import React from "react"
import classNames from "classnames"

import styles from "./WhyUseIt.module.css"

const WhyUseIt = () => (
  <div className={styles.sectionWrapper}>
    <h2 className={styles.title}>Why use it</h2>
    <div className={classNames("container", styles.container)}>
      <p>
        The Select Residential Property group are the creators of
        Suburbgrowth.com.au and other leading residential property research
        software. We’re all about providing time saving and affordable, data
        driven research tools for everyday Australians to help them make better
        informed decisions about where to buy and what to buy or sell.
      </p>
      <p>
        The creator of Suburbgrowth.com.au and the Director of Research at
        Select Residential Property group – Jeremy Sheppard, is an expert in the
        field of identifying capital growth opportunities within the Australian
        residential property market. He is passionate about helping property
        buyers and sellers make smarter buying or selling decisions, based on
        pure data, instead of emotion or hearsay.
      </p>
      <p>
        Suburbgrowth.com.au’s Property Prediction Report is an incredibly cost
        effective and easy to use tool, designed to provide you with immediate
        insights and validation of any property market you are considering.
      </p>
    </div>
  </div>
)

export default WhyUseIt
