import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import WhyUseIt from "../components/WhyUseIt/WhyUseIt"

const WhyUseItPage = () => (
  <SiteLayout>
    <SEO title="Why use it" />
    <WhyUseIt />
  </SiteLayout>
)

export default WhyUseItPage
